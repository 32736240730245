<template>
  <div class="">
    <v-btn
      color="secondary"
      @click="onPaymentPremiunVideo"
    >
      Finished
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const { route } = useRouter()

    const saveCard = ref(null)
    const patmentIntent = ref(null)

    const onPaymentPremiunVideo = async () => {
      const body = {
        payment_intent_id: patmentIntent.value,
        save_card: saveCard.value,
      }
      console.log({ body })
    }

    onMounted(() => {
      const { params, query } = route.value
      saveCard.value = params.saveCard
      patmentIntent.value = query.payment_intent
    })

    return {
      patmentIntent,

      onPaymentPremiunVideo,
    }
  },
}
</script>
